<template>
  <div>
    <v-dialog
      v-model="show"
      scrollable
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="700px"
      @keydown.esc="show = false"
    >
      <v-card class="action-modal">
        <v-card-title
          class="px-4 py-3 primary font-weight-bold secondary--text text-center"
        >
          <v-icon v-text="'$calendar-clock'" color="white" />
          <span
            v-for="(text, i) in title_text"
            :key="`title_${i}`"
            class="ml-3"
            >{{ text }}</span
          >
        </v-card-title>
        <div class="customer-details br-8 mx-7 mt-7 px-5 py-3">
          <div
            v-for="(data, i) in customer_detail_data"
            :key="`customer_detail_${i}`"
            :class="data.class"
            class="my-2"
            v-show="data.show"
          >
            <v-icon
              v-text="data.icon"
              class="rounded-circle mr-1"
              :class="data.circle"
              :color="data.color"
            />
            <span class="dark-grey--text"> {{ data.text }} </span>
          </div>
        </div>
        <div class="booking-details br-8 mx-7 my-5">
          <v-card-subtitle
            class="text-h5 font-weight-bold text-center mt-2"
            :class="{
              'mt-7': $vuetify.breakpoint.smAndDown,
              'accent--text text--darken-1':
                selected_booking.status == 'cancelled',
              'dark-grey--text': selected_booking.status != 'cancelled',
            }"
          >
            {{ selected_booking.status == "cancelled" ? "Cancelled " : "" }}
            {{ selected_booking.is_group_booking ? "Group " : " " }}Booking
            Details</v-card-subtitle
          >
          <div
            class="
              booking-status-text
              font-italic
              dark-grey--text
              d-flex
              flex-column
              align-end
            "
          >
            <div
              :class="BOOKING_STATUS_CHIP_DATA[selected_booking.status].class"
            >
              <span class="font-weight-medium">Booking Status: </span>
              <span>{{
                BOOKING_STATUS_CHIP_DATA[selected_booking.status].text
              }}</span>
              <v-icon x-small class="ml-1">
                {{ BOOKING_STATUS_CHIP_DATA[selected_booking.status].icon }}
              </v-icon>
            </div>
            <div>
              <span class="font-weight-medium">Allocated Table: </span>
              <span>{{ selected_booking.table.name }}</span>
            </div>
          </div>
          <div class="booking-details-text mb-1">
            <div
              v-for="(data, i) in booking_detail_data"
              :key="`booking_detail_${i}`"
              class="d-flex align-center"
              style="gap: 0.3rem"
            >
              <span class="font-weight-medium dark-grey--text">{{
                `${data.title}: `
              }}</span>
              <span class="dark-grey--text">{{ data.text }}</span>
              <template v-if="data.actions">
                <v-btn
                  v-for="(action_data, i) in data.actions"
                  :key="`action_${i}`"
                  :disabled="action_data.disabled"
                  outlined
                  rounded
                  text
                  small
                  color="primary"
                  @click="action_data.action"
                  ><span class="font-weight-bold">{{
                    action_data.action_text
                  }}</span></v-btn
                >
              </template>
            </div>
            <div class="update-booking d-flex justify-center align-center py-3">
              <v-btn
                v-if="
                  selected_booking.status != 'cancelled' &&
                  !selected_booking.is_group_booking
                "
                color="#2bd7de"
                rounded
                outlined
                @click="toggle_show_new_booking"
              >
                Edit Booking
              </v-btn>
            </div>
          </div>
        </div>
        <div class="total d-flex justify-end mx-7 my-1" v-if="false">
          <span
            class="
              font-weight-medium
              text-decoration-underline
              dark-grey--text
              mr-1
            "
            >Total:</span
          >
          <span class="dark-grey--text">£0</span>
        </div>
        <div class="mt-3 mb-7 mx-6 d-flex justify-space-between">
          <BookingPaymentIntentControl
            v-if="selected_booking.status === 'no_show'"
            :intent_id="selected_booking.payment_intent_id"
            :customer_id="selected_booking.user.stripe_customer_id"
            :booking_id="selected_booking.id"
          />
          <v-spacer v-else />
          <BookingDetailActions
            :booking="selected_booking"
            :props="{ color: 'primary' }"
            @update_booking="update_booking_status"
            :loading="is_sending"
            @close="show = false"
            @print="print_booking"
          />
        </div>
      </v-card>
    </v-dialog>
    <v-simple-table fixed-header class="booking-printable">
      <thead>
        <tr>
          <th class="text-center">Date</th>
          <th class="text-center">Time</th>
          <th class="text-center">Guest Name</th>
          <th class="text-center">Email</th>
          <th class="text-center">Phone No</th>
          <th class="text-center">Guest Count</th>
          <th class="text-center">Zone</th>
          <th class="text-center">Table</th>
          <th class="text-center">Notes</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th class="text-center">
            {{ moment(selected_booking.start_at).utc().format("DD/MM/YYYY") }}
          </th>
          <th class="text-center">
            {{ moment(selected_booking.start_at).utc().format("HH:mm") }}
          </th>
          <th class="text-center">{{ selected_booking.user.name }}</th>
          <th class="text-center">{{ selected_booking.user.email }}</th>
          <th class="text-center">{{ selected_booking.user.phone_no }}</th>
          <th class="text-center">{{ selected_booking.guest_count }}</th>
          <th class="text-center">
            {{ get_zone_name_from_table_id(selected_booking.table.id) }}
          </th>
          <th class="text-center">{{ selected_booking.table.name }}</th>
          <th class="text-center">{{ selected_booking.notes }}</th>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import moment from "moment";
//import capitalize from "lodash/capitalize";

import { mapActions, mapState } from "vuex";
import { BOOKING_STATUS_CHIP_DATA } from "@/shared/const";

import BookingDetailActions from "@/views/dashboard/pages/table_bookings/components/BookingDetailActions";
import BookingPaymentIntentControl from "@/views/dashboard/pages/table_bookings/components/BookingPaymentIntentControl";
import { patch_booking } from "@/requests";

export default {
  name: "ViewBookingDialog",
  components: {
    BookingDetailActions,
    BookingPaymentIntentControl,
  },
  data() {
    return {
      moment,
      BOOKING_STATUS_CHIP_DATA,
    };
  },
  computed: {
    ...mapState("StaffStore", ["staff"]),
    ...mapState("TableBookingStore", [
      "selected_booking",
      "zones",
      "tables",
      "is_sending",
      "settings",
    ]),
    show: {
      get() {
        return this.selected_booking != null;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
        this.$emit("refresh-data"); // Changes by Vikas Table Booking
      },
    },
    title_text() {
      const date_text = moment(this.selected_booking.start_at)
        .utc()
        .format("DD/MM/YYYY");
      const time_text = moment(this.selected_booking.start_at)
        .utc()
        .format("HH:mma");
      const guest_unit =
        this.selected_booking.guest_count > 1 ? "People" : "Person";

      return [
        date_text,
        time_text,
        this.selected_booking.extra_guest_count
          ? `${this.selected_booking.guest_count} + ${this.selected_booking.extra_guest_count} People`
          : `${this.selected_booking.guest_count} ${guest_unit}`,
      ];
    },
    customer_detail_data() {
      let name;

      if (this.selected_booking.staff_id) {
        name =
          this.staff.find((s) => s.id == this.selected_booking.staff_id)
            ?.name || null;
      }
      if (!name) name = this.selected_booking.user.name;
      if (this.selected_booking.user.role != "user") {
        // const role_suffix = this.selected_booking.user.role
        //   .split("_")
        //   .map((word) => capitalize(word))
        //   .join(" ");
        name += "";
      }

      return [
        {
          text: name,
          icon: "$account",
          class: "",
          color: "white",
          circle: "primary",
          show: !!this.selected_booking.user.name, // Show if name exists
        },
        {
          text: this.selected_booking.user.email || "No Email Provided",
          icon: "$mail",
          class: "",
          color: "white",
          circle: "primary",
          show: !!this.selected_booking.user.email, // Show only if email exists
        },
        {
          text: this.selected_booking.user.phone_no || "No Phone Provided",
          icon: "$phone",
          class: "",
          color: "white",
          circle: "primary",
          show: !!this.selected_booking.user.phone_no, // Show if phone number exists
        },
        {
          text:
            this.selected_booking.user && this.selected_booking.user.email
              ? "Accepted to receive marketing emails."
              : "Walk-In",
          icon: "$check",
          class: "font-italic",
          color: "dark-grey",
          circle: "light-grey",
          show: true, // Always show
        },
        {
          text: this.selected_booking.notes || "No Notes",
          icon: "$notes",
          class: "notes",
          color: "white",
          circle: "primary",
          show: this.selected_booking.notes !== null,
        },
      ];
    },
    // Changes done by vikas for Table booking 30 Nov
    booking_detail_data() {
      return [
        {
          title: "Date",
          text: moment(this.selected_booking.start_at)
            .utc()
            .format("Do MMMM YYYY, dddd"),
        },
        {
          title: "Start",
          text: moment(this.selected_booking.start_at).utc().format("HH:mma"),
          actions: [
            {
              action_text: `-${this.settings.interval} min`,
              action: async () => {
                const newStartAt = moment(this.selected_booking.start_at)
                  .utc()
                  .subtract(this.settings.interval, "minutes")
                  .format("YYYY-MM-DDTHH:mm:ss");

                // API call to update start_at
                await patch_booking(this.selected_booking.id, {
                  start_at: newStartAt,
                });

                // Update the local data after successful API call
                this.selected_booking.start_at = newStartAt;
              },
            },
            {
              disabled: moment(this.selected_booking.start_at)
                .utc()
                .isSameOrAfter(moment(this.selected_booking.end_at).utc()),
              action_text: `+${this.settings.interval} min`,
              action: async () => {
                const newStartAt = moment(this.selected_booking.start_at)
                  .utc()
                  .add(this.settings.interval, "minutes")
                  .format("YYYY-MM-DDTHH:mm:ss");

                // API call to update start_at
                await patch_booking(this.selected_booking.id, {
                  start_at: newStartAt,
                });

                // Update the local data after successful API call
                this.selected_booking.start_at = newStartAt;
              },
            },
          ],
        },
        {
          title: "No of People",
          text: this.selected_booking.extra_guest_count
            ? `${this.selected_booking.guest_count} + ${this.selected_booking.extra_guest_count}`
            : this.selected_booking.guest_count,
        },
        {
          title: "End",
          text: moment(this.selected_booking.end_at).utc().format("HH:mma"),
          actions: [
            {
              disabled: moment(this.selected_booking.end_at)
                .utc()
                .isSameOrBefore(moment(this.selected_booking.start_at).utc()),
              action_text: `-${this.settings.interval} min`,
              action: async () => {
                const newEndAt = moment(this.selected_booking.end_at)
                  .utc()
                  .subtract(this.settings.interval, "minutes")
                  .format("YYYY-MM-DDTHH:mm:ss");

                // API call to update end_at
                await patch_booking(this.selected_booking.id, {
                  end_at: newEndAt,
                });

                // Update the local data after successful API call
                this.selected_booking.end_at = newEndAt;
              },
            },
            {
              action_text: `+${this.settings.interval} min`,
              action: async () => {
                const newEndAt = moment(this.selected_booking.end_at)
                  .utc()
                  .add(this.settings.interval, "minutes")
                  .format("YYYY-MM-DDTHH:mm:ss");

                // API call to update end_at
                await patch_booking(this.selected_booking.id, {
                  end_at: newEndAt,
                });

                // Update the local data after successful API call
                this.selected_booking.end_at = newEndAt;
              },
            },
          ],
        },
        {
          title: "Location",
          text:
            this.get_zone_name_from_table_id(this.selected_booking.table.id) ||
            "",
        },
      ];
    },
    // Changes done by vikas for Table booking 30 Nov till here
  },
  methods: {
    ...mapActions("TableBookingStore", [
      "select_booking",
      "toggle_show_new_booking",
      "update_booking",
    ]),
    update_booking_status(status) {
      const booking = { ...this.selected_booking };
      booking.status = status;
      this.update_booking(booking);
    },
    get_zone_name_from_table_id(table_id) {
      const table = this.tables.find((t) => t.id === table_id);
      const zone = this.zones.find((z) => z.id === table?.zone_id);
      return zone?.name;
    },
    print_booking() {
      const doc_title = document.title;
      const new_title = `We Are Locals | Booking for ${this.selected_booking.user.name}`;

      window.onafterprint = () => {
        document.title = doc_title;
      };

      document.title = new_title;
      window.print();
    },
  },
};
</script>
<style lang="scss" scoped>
.booking-printable {
  visibility: hidden;
  position: absolute;
  max-height: 0;
  overflow-y: hidden;
}
.v-dialog .v-dialog--active ::v-deep {
  border-radius: 16px !important;
}
.customer-details {
  display: grid;
  grid-template-columns: 240px;
  grid-template-rows: auto auto auto auto;
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    grid-template-columns: 240px auto;
    grid-template-rows: auto auto auto;
  }
  background: var(--v-light-grey-base);
  .notes {
    grid-area: 3/1/4/3;
  }
}
.booking-details {
  border: solid 1px #707070;
  position: relative;
  .booking-details-text {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // grid-template-rows: auto auto;
    row-gap: 10px;
    margin: 0 59px;
    .update-booking {
      grid-column: 1/3;
    }
  }
  .booking-status-text {
    position: absolute;
    top: 5px;
    right: 15px;
  }
}
.update-button ::v-deep {
  background: #e4f6f6;
}
</style>
<style lang="scss">
@media print {
  .v-application--wrap {
    visibility: hidden !important;
  }
  .v-dialog {
    visibility: hidden !important;
  }
  .booking-printable {
    max-height: 100% !important;
    overflow-y: visible !important;
    visibility: visible !important;
    top: -50px;
    left: -260px;
    min-width: 1000px !important;
    tr {
      height: 30px !important;
      th {
        height: 30px !important;
        width: 125px !important;
        &:last-of-type {
          width: 250px !important;
        }
      }
    }
  }
}
</style>
